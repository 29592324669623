import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { TopicCard } from '@conventic-web/internal.cvt.surfaces.topic-card';
import { useWindowDimensions } from '@conventic-web/internal.utility';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const SpecialTopicsPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const { width } = useWindowDimensions();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  const styles = {
    container: {
      topicCard: {
        all: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        },
        single: { display: 'flex', margin: '2em 8em' },
      },
    },
  } as const;

  const createSpecialTopicCard = (topicList: any) => {
    return topicList.map((element: any, index: number) => {
      return (
        <div
          style={styles.container.topicCard.single}
          key={`topic-card-${index}`}
        >
          <TopicCard
            headline={element.topic}
            text={element.content}
            color={CvtColorSchema.white}
            bgColor={CvtColorSchema.green}
            hoverColor={CvtColorSchema.yellow}
            linkPath={element.link}
            maxWidth={width < 600 ? 150 : 300}
          />
        </div>
      );
    });
  };

  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />

      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />
      <BlockStandard
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
        subheadline={frontmatter.specialTopicMain.headline}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.specialTopicMain.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
      <BlockStandard
        bgColor={CvtColorSchema.bgDarkGray}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={6}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.white}
        subheadline={frontmatter.specialTopicServices.headline}
      >
        <div style={styles.container.topicCard.all}>
          {createSpecialTopicCard(frontmatter.specialTopicServices.topicList)}
        </div>
      </BlockStandard>
    </Layout>
  );
};

SpecialTopicsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SpecialTopicsPage;

export const specialTopicsPagePageQuery = graphql`
  query SpecialTopicsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        specialTopicMain {
          content
          headline
        }
        specialTopicServices {
          headline
          topicList {
            topic
            content
            link
          }
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageDescription
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
